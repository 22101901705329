import {
  IAuthActionTypes,
  IAuthActionCreator,
  IAuthState,
  INewUserActionTypes,
} from "models/IAuthState";

const initialState: IAuthState = {
  isLoading: false,
  isSigningIn: false,
  isCreateUserLoading: false,
  isResetPasswordLoading: false,
  isUpdatePasswordLoading: false,
  user: null,
  role: null,
  isLoggedIn: false,
  code: "",
  isAdmin: {},
  is_new: false,
  customer_unique_id: "",
  isCartOpen: false,
  cartItems: [],
  customerCredits: 0, // Total credits available to the customer
  creditsUsed: 0, // Total credits used in the cart
  remainingBalance: 0, // Remaining balance after adding items to the cart
  campaign: {},
  customer: {},
  cartValue: 0,
  ishaveBalance: true,
  isLoginModalOpen: false,
  twofaRequest: false,
  isSignupModalOpen: true,
};

const reducer = (
  state = initialState,
  { type, payload }: IAuthActionCreator
) => {
  switch (type) {
    case IAuthActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isSigningIn: true,
      };
    case IAuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isSigningIn: false,
        isLoginModalOpen: false,
        isLoggedIn: true,
      };
    case IAuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isSigningIn: false,
      };

    case IAuthActionTypes.HANDLE_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: payload.open,
      };
    case IAuthActionTypes.HANDLE_TWO_FA:
      return {
        ...state,
        twofaRequest: payload,
      };

    case IAuthActionTypes.HANDLE_SIGNUP_MODAL:
      return {
        ...state,
        isSignupModalOpen: payload.open,
      };
    case IAuthActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPasswordLoading: true,
      };

    case IAuthActionTypes.RESET_PASSWORD:
      return {
        ...state,
        isResetPasswordLoading: false,
      };
    case IAuthActionTypes.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isUpdatePasswordLoading: true,
      };
    case IAuthActionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        isUpdatePasswordLoading: false,
      };
    case IAuthActionTypes.LOGOUT:
      return { ...initialState };
    case IAuthActionTypes.SILENT_LOGIN:
      return {
        ...state,
        user: payload.user,
        role: payload.role,
        code: payload.code,
      };
    case IAuthActionTypes.SET_CART_OPEN: {
      return {
        ...state,
        isCartOpen: payload.open,
      };
    }

    case IAuthActionTypes.TWOFA_REQUEST:
      return {
        ...state,
        isSigningIn: true,
        twofaRequest: true,
      };
    case IAuthActionTypes.TWOFA_SUCCESS:
      return {
        ...state,
        isSigningIn: false,
        twofaRequest: false,
      };
    case IAuthActionTypes.TWOFA_FAILURE:
      return {
        ...state,
        user: null,
        twofaRequest: false,
        isSigningIn: false,
      };
    case IAuthActionTypes.RESEND_TWOFA_REQUEST:
      return {
        ...state,
        resendTwoFa: payload.sent,
        isSigningIn: true,
        twofaRequest: true,
      };
    case IAuthActionTypes.RESEND_TWOFA_SUCSESS:
      return {
        ...state,
        twofaRequest: true,
        isSigningIn: true,
        resendTwoFa: true,
      };
    case IAuthActionTypes.RESEND_TWOFA_FAILURE:
      return {
        ...state,
        twofaRequest: true,
        isSigningIn: true,
        resendTwoFa: false,
      };
    case INewUserActionTypes.SET_USER_PROFILE: {
      return {
        ...state,
        user: payload,
      };
    }
    case INewUserActionTypes.SET_USER_PROFILE_REQUEST: {
      return {
        ...state,
      };
    }

    case INewUserActionTypes.SET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        customerCredits: payload.creditBalance,
        remainingBalance: payload.creditBalance,
        creditsUsed: payload.creditsUsed,
        isLoggedIn: true,
        is_new: payload.is_new,
        user: { ...state.user, user: payload },
      };
    }

    case IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE:
      return {
        ...state,
        isLoading: true,
      };

    case IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customerCredits: payload.data.creditBalance,
        user: { ...state.user, user: payload.data },
      };

    case IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case IAuthActionTypes.SET_REQUEST_ADD_TO_CART:
      return {
        ...state,
        isLoading: true,
      };

    case IAuthActionTypes.SET_REQUEST_ADD_TO_CART_SUCCESS:
      const cartItems = state.cartItems;
      const itemExist = cartItems.find(
        (item: any) => item.id == payload.product.id
      );
      if (itemExist) {
        if (itemExist.cartCount == payload.product.cartCount) {
          return {
            ...state,
            isCartOpen: true,
          };
        } else {
          const updatedCartItems = cartItems.map((item: any) => {
            if (item.id === payload.product.id) {
              return {
                ...item,
                cartCount: payload.product.cartCount,
              };
            }
            return item;
          });
          return {
            ...state,
            cartItems: updatedCartItems,
            isCartOpen: payload.openDrawer,
          };
        }
      }
      return {
        ...state,
        isLoading: false,
        isCartOpen: payload.openDrawer,
        cartItems: [...state.cartItems, payload.product],
        errorMessage: null,
      };
      return {
        ...state,
        isLoading: false,
        cartItems: [...state.cartItems, payload],
        errorMessage: null,
      };

    case IAuthActionTypes.SET_REQUEST_ADD_TO_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload, // Store the error message
      };

    case IAuthActionTypes.SET_REQUEST_CHECKOUT:
      return {
        ...state,
        isLoading: true,
      };

    case IAuthActionTypes.SET_REQUEST_CHECKOUT_SUCCESS:
      return {
        ...state,
        isCartOpen: false,
        cartItems: [],
        customerCredits: payload.user.creditBalance, // Total credits available to the customer
        creditsUsed: 0, // Total credits used in the cart
        cartValue: 0,
        remainingBalance: payload.user.creditBalance, // Remaining balance after adding items to the cart
        isLoading: false,
      };

    case IAuthActionTypes.SET_REQUEST_CHECKOUT_FAILURE:
      const resourceIdsToRemove = payload.map((item: any) => item.rid);
      const updatedCartItems = state.cartItems.filter(
        (item: any) => !resourceIdsToRemove.includes(item.reward)
      );

      // Calculate the new values for creditsUsed, cartValue, and remainingBalance
      const newCreditsUsed = updatedCartItems.reduce(
        (total: any, item: any) => total + item.credit,
        0
      );
      const cartValue = updatedCartItems.reduce(
        (total: any, item: any) => total + item.credit,
        0
      );
      const newRemainingBalance = state.customerCredits - newCreditsUsed;
      return {
        ...state,
        isLoading: false,
        cartItems: updatedCartItems,
        creditsUsed: newCreditsUsed, // Total credits used in the cart
        cartValue: cartValue, // Total cart value
        remainingBalance: newRemainingBalance, // Remaining balance after adding items to the cart
      };

    case IAuthActionTypes.SET_REQUEST_REMOVE_FROM_CART:
      return {
        ...state,
        isLoading: true,
      };

    case IAuthActionTypes.SET_REQUEST_REMOVE_FROM_CART_SUCCESS:
      const removedItem = state.cartItems.find(
        (item: any) => item.id === payload
      );
      if (removedItem) {
        return {
          ...state,
          isLoading: false,
          isCartOpen: false,
          cartItems: state.cartItems.filter((item: any) => item.id !== payload),
          cartValue: state.cartValue - removedItem.credit,
          remainingBalance:
            state.customerCredits - state.cartValue + removedItem.credit,
          errorMessage: null,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          isCartOpen: false,
          errorMessage: "Item not found in the cart.",
        };
      }

    case IAuthActionTypes.SET_REQUEST_CLEAR_CART_SUCCESS:
      return {
        ...state,
        cartItems: [], // Clear the cart by resetting cartItems to an empty array
      };

    default:
      return state;
  }
};

export default reducer;
