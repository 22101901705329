import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/Routes";
import { ToastContainer } from "react-toastify";

import Footer from "components/Footer";
import { connect, useDispatch } from "react-redux"; // Removed unnecessary import

import GenericLoader from "components/GenericLoader";
import "sweetalert2/src/sweetalert2.scss";
import "react-multi-carousel/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { setLoading } from "redux/actions/app.action";
import authService from "services/authService";
import "./App.scss";

const App = ({ state, isLoading }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  try {
    return (
      <Router basename="/">
        <Routes />
        <ToastContainer position="top-center" />
        {/* <CompleteProfileModal />
        <LoginModal /> */}
        {authService.isAdmin() ? null : <Footer />}
        <GenericLoader open={isLoading} />
      </Router>
    );
  } catch (error) {
    console.error("An error occured");
    // dispatch(sessionExpired());
  }
};

const mapStateToProps = (state: any) => ({
  state: state.auth,
  isLoading: state.app.isLoading,
});

export default connect(mapStateToProps)(App);
