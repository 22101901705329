export const PATH_NAME = {
  // General routes
  ROOT: "/",
  ERROR_404: "/404",
  ERROR_403: "/403",
  FAQ: "/faq",
  OFFICIAL_RULES: "/rules",
  LOGIN: "/login",
  ADMIN_LOGIN: "/admin/login",
  REGISTER: "/register",
  CONFIRMATION: "/confirmation",

  //logout
  LOGOUT: "/logout",

  // Cart routes
  CART: "/cart",

  // Checkout routes
  CHECKOUT: "/checkout",

  //Dashboard
  DASHBOARD: "/dashboard",

  //Manage Customer
  MANAGE_CUSTOMERS: "/dashboard/manage-customers",
  ADD_CUSTOMER: "/dashboard/manage-customers/add",
  UPDATE_CUSTOMER: "/dashboard/manage-customers/update",
  CUSTOMERS_USER: "/dashboard/manage-customers/users",
  USER_DETAILS: "/dashboard/manage-customers/view",

  //Signup Code
  MANAGE_CODES: "/dashboard/manage-codes",
  //RedemptionCode
  REDEMPTION_CODE: "/dashboard/redemption-code",

  //Terms & condition
  ADD_TERMS: "/dashboard/terms/add",
  TERMS: "/dashboard/terms",
  UPDATE_TERMS: "/dashboard/terms/update",

  FAQS: "/dashboard/manage-faqs",
  FAQS_ADD: "/dashboard/faq/add",
  FAQS_UPDATE: "/dashboard/faq/update",

  // USER_LISTING: '/dashboard/setting/manage-user/users',
  // UPDATE_USER: '/dashboard/setting/manage-user/:id',
};
